export const content = {
  en: {
    translations: {
      comingSoon: "Comming Soon",
      header: {
        home: "Home",
        clinic: "The Clinic",
        treatments: "Treatments",
        experience: "The Experience",
        blog: "Blog EHTIQ",
        about: "About EHTIQ",
        contacts: "Contacts",
        membership: "Membership",
        privacyPolicy: "Privacy Policy",
        privacyCookies: "Cookie Policy",

        account: "My account",
        login: "Logar",
        logout: "Sign Out",
        admin: "Admin",
      },
      footer: {
        schedulle: "Appointments",
        contact: "Contact Us",
        ourPolicies: "Our Policies",
        registerConcern: "Register Complaint",
        copyright: "Copyright 2024. Desenvolvido por LINEA AGENCY.",
        privacyPolicy: "Privacy & Cookies",
        privacyCookies: "Terms of Use",
      },
      expertAdvice: {
        box0: "Smart search",
        box1: "Choose a filter",
        box2: "EHTIQ</br> Specialties",
        box21: "EHTIQ Specialties",
        box3: "Body</br> Parts",
        box31: "Body Areas",
        box4: "Concerns</br> A-Z",
        box41: "Concerns A-Z",
        box5: "Return to main filters",
        box6: "Restart",
      },
      visitCard: {
        box1: "Find us",
        box11: "Contact us",
        box2: "Book",
        box3: "A brilliant encounter",
        box4: "Contact us to schedule your appointment.</br>See you soon",
      },
      pages: {
        identityBox: {
          box1: "Specialists in",

          box2: "Identity Protection",
          box3: "Confidentiality",
          box4: "Privacy",
          box5: "Data Protection",
          box6: "Schedule your appointment",
          button1: "Contact",
        },
        about: {
          box0: "About ehtiq",
          box1: "OUR PROMISSE IS</br> YOUR WELL-BEING",
          box2: "The human eternal search for wellness is a quest that spans the history of our existence.</br></br>It's the pursuit of an optimal state of health and well-being, not just physically, but emotionally and spiritually as wel",
          box3: "Desing de @laviebypi",
          box4: "Located in one of the most NOBLE areas of the city of Lisbon, EHTIQ emerges as a symbol of excellence combined with tradition.",
          box5: "Discover the space designed to exceed your expectations, promoting the highest quality in healthcare.",
          box6: "We elevate the <b>standards of quality</b> in healthcare.",
          box7: "VALUES THAT REPRESENT</br> EXCELLENCE",
          box8: "Elegance",
          box9: "Harmony",
          box10: "Tranquility",
          box11: "Innovation",
          box12: "Quality",
          box13: "The experience",

          box14:
            "Enter a universe carefully created to provide a unique experience.</br></br><b class='custom-bold'>Specialized teams, scientific approach</b>, and focus on experience are our commitments.",

          button1: "Enter",
        },
        home: {
          text1: "Fusion of Beauty and Well-being",
          text2:
            "A space of sophistication and self-discovery that drives through a horizon of beauty well-being.",
          box31: "Aesthetic &</br> Surgical",
          box32: "Dental",
          box33: "Post-surgical</br> & Wellness",
          box34: "Preventive Health",
          box41: "Intelligent Search",
          box42: "Find the treatments that suit you.",
          box61: "Concierge Services",
          box62: "Discover the",
          box622: "EHTIQ",
          box623: "Standard",
          box624: "Featured",
          box63:
            "The Concierge Service is designed to offer maximum convenience, eliminating logistical concerns and optimizing your time.</br>From scheduling appointments to priority access to specialists and treatments, our Concierge team is on hand to ensure your visit is smooth and comfortable.",
          button0: "Expert Advice",
          button1: "Request Concierge",
          button2: "The Clinic",
          icon1: "Hotel",
          icon2: "Travel",
          icon3: "Transport",
          icon4: "Concierge",
          box71: "Discover Where We Take Care Of you",
        },
        schedulleBox: {
          box1: "Appointments",
          box2: "Schedule online or make your appointment with our assistants.",
          box3: "Membership",
          box4: "Explore the exclusive benefits with our Membership Access.",
          button1: "SCHEDULE NOW",
          button2: "Coming Soon",
        },
        clinic: {
          box11: "Passion for detail",
          box12:
            "Elements of modern architecture, elegant tones and warm lighting.",
          box13: "The EHTIQ Bar",
          box14:
            "Created to provide the best waiting moments in the company of our team.",
          box15: "Post-surgical & Wellness",
          box16:
            "The serenity brought by welcoming elements and high attention to detail.",
          box17: "Nany Mota Waiting Room",
          box18:
            "We know there’s no other space where you’d rather wait for us.",
          box21:
            "Elegance and innovation, where each space is</br> a celebration of aesthetic.",
          box31: "Post-Surgical & Wellness Waiting Room by</br> Nany Mota",
          box312: "Post-Surgical & Wellness Waiting Room by Nany Mota",
          box32: "Access to Dental and</br> Post-Surgical Units",
          box33: "Private areas with premium finishes",
          box41: "Eyes On",
          box412: "",
          box413: "Customization",
          box42:
            "At EHTIQ, personalization is the essence of our philosophy. We shape each treatment to reflect your aspirations.",
          box43:
            "We believe that beauty is an individual journey, tailored to enhance your uniqueness.",
          button1: "THE EXPERIENCE",
        },
        treatments: {
          box01: "Treatments",
          box0: "Signatures",
          box1: "Aesthetic & Surgical",
          box2: "We have combined the best Professionals with the best Technology on the market, to guarantee exceptional results and the highest clinical monitoring.",
          box3: "HIGH DEFINITION LIPO",
          box4: "An innovative and exclusive approach, which allows you to highlight muscle contours and emphasize you body’s best details, leaving Conventional Liposuction in the past.",
          box5: "EHTIQ ELIXIR",
          box6: "Designed to counteract the signs of time, restoring firmness, elasticity and radiance. A cocktail of powerful ingredients, formulated for diSerent areas of the body.",
          box7: "EHTIQ AGELESS Neck & Neckline",
          box8: "The cutting edge of technology associated with the best formulas on the market. An anti-aging protocol with unparalleled results.",
          box9: "Post-Surgical & Wellness",
          box10:
            "Post-operative care performed with Nany Mota® Technique and Personalized Protocols to ensure the well-being of body and mind.",
          box11: "POST-SURGICAL MASSAGE BY NANY MOTA",
          box12:
            "A reference in post-surgical follow-up, with the exclusive Nany Mota® technique.",

          box13: "GLASS SKIN BY HYDRAFACIAL",
          box14:
            "The combination of cleansing, exfoliation, extraction, and hydration in a single process, thanks to HydraFacial technology.",
          box15: "Preventive Health",
          box16:
            "The revolution of clinical practice focusing on physical, mental, and emotional balance. We left the interventional concept to give way to longevity.",
          box17: "HORMONAL REPLACEMENT",
          box18:
            "The powerful aid against fatigue, mood changes, and weight gain, achieved with personalized protocols.",
          box19: "REPOSIÇÃO VITAMÍNICA IV DRIPS",
          box20:
            "Achieved with essential nutrients that flow directly into the bloodstream, revitalizing your body from the inside out.",
          box21: "Dental",

          box22:
            "Specializing in hand-crafted Dental Veneers, the EHTIQ Dental Unit is characterized by high standards of excellence and sensitivity.",
          box23: "NEXT-GENERATION VENEERS",
          box24:
            "Thin layers of ceramic or composite resin, hand-crafted for a beyond-natural effect.",
          box25: "INVISIBLE ORTHODONTICS",
          box26:
            "Invisible aligners that ensure comfort and maintain the aesthetics of your smile throughout the process.",
          box27: "Service Selection",
          box28: "EHTIQ Full Range",
          box29: "BY SPECIALTY",
          box30: "BY BODY AREAS",
        },
        body: {
          box1: "Face",
          box2: "Smile",
          box3: "Body",
          box4: "Health 360º",
        },
        privacyPolicy: {
          title: "Privacy Policy",
          subtitle1: "Commitment to Data Protection and Privacy",
          text1:
            "EHTIQ and its internal elements directly related to it are committed to secrecy and confidentiality in the processing of personal data for which they are responsible, in accordance with this Privacy Policy.",
          subtitle2: "Responsible Entity and Contact",
          text2:
            "Elevate Plures Investments, Lda, headquartered at Avenida Duque de Loulé, 24 - 7º Esq. - 1050-090 Lisbon, corporate entity no. 516677373, and owner of the EHTIQ brand, is the entity responsible for the processing of personal data contained and managed on www.ehtiq.com and its subpages (hereinafter referred to as the EHTIQ digital area or platform), either directly or through computerized applications contained therein, through which Users have remote access to its services and products, whether they are presented, marketed, or provided at any time through its platform.</br></br>Any clarification or request related to this Privacy Policy can be sent to info@ehtiq.com, accompanied by a direct contact, first and last name, and/or other personal data relevant to the described matter.",
          subtitle3: "Personal Data Processed",
          text3:
            "EHTIQ declares to process the following main personal data, among others that may prove necessary and are legitimately collected:</br></br>- Full name</br></br>- Email</br></br>- Date of birth</br></br>- Tax number</br></br>- Civil ID number</br></br>- Marital status</br></br>- Gender</br></br>- Place of birth</br></br>- Address (street, postal code, city, country)</br></br>- Public social networks",
          subtitle4: "Purpose of Data Processing",
          text4:
            "The personal data processed in the EHTIQ digital area is intended for:</br></br>- Providing information directly to the user</br></br>- Management deemed necessary for internal communication and/or communication with the appropriate user</br></br>- Provision of services supported by suppliers whose data processing and confidentiality guarantees are properly expressed and appear to be strictly adhered to</br></br>- Information or promotional dissemination actions for commercial or marketing purposes, namely the dissemination of new products and services through direct communication, whenever consented to",
          subtitle5: "User Responsibility",
          text5:
            "The User is solely responsible for maintaining the confidentiality of their platform access credentials, not sharing them with third parties. They must also keep their access devices secure and follow the practices recommended by manufacturers and/or operators, including the installation and updating of necessary security applications, such as antivirus software.",

          subtitle6: "Express Consent",
          text6:
            "The free, specific, and informed provision of personal data by its respective holder implies knowledge and acceptance of this Policy and constitutes express authorization for its processing in accordance with the practices defined above.",
          title2: "Cookie Policy",
          subtitle7: "Use of Cookies",
          text7:
            "The <a>www.ehtiq.com</a> area and its subpages use cookies to improve the user experience. Below, we explain how we do it.",
          subtitle8: "What are Cookies?",
          text8:
            "Cookies are small files stored on your computer or mobile device through your browser (Internet browser). They allow monitoring of part of your activity on a particular platform to optimize your usage experience. Their use on the web is common and does not harm your hardware or software.",
          subtitle9: "Purpose of Our Cookies",
          text9:
            "Our cookies collect generic information, such as how the user arrived at our site, the country from which they access, among others, retaining only information related to their preferences and usage patterns. The cookies we use fall into two categories:</br></br><b>Permanent</b> – Stored at the browser level on your access devices (PC, mobile, and tablet) and used whenever you make a new visit to the EHTIQ digital area.</br></br><b>Session cookies</b> – Temporary and remain in the cookie file of your browser until you leave the EHTIQ digital area. The information obtained by these cookies is used to analyze web traffic patterns, allowing us to identify problems and provide a better browsing experience.",
          subtitle10: "User Cookie Management",
          text10:
            "At any time, the user can check which cookies they have on their computer, delete them, or block access to them. All of this is done directly in their internet browser. If you block our cookies, you may have difficulty accessing some areas of the site.",
          title3: "Terms of Use",
          subtitle11: "1 . Agreement with Policies",
          text11:
            "By using the EHTIQ digital area, the user agrees to the Privacy Policies and Cookie Policies communicated therein.",
          subtitle12: "2 . Terms of Use",
          text12:
            "The visitor and/or user of the EHTIQ digital area agrees to the proper and lawful use of its platform, as well as its content and services, in accordance with these Terms of Use, and must not:</br></br>a) Use the platform in an unauthorized or fraudulent way for illegal purposes, harmful to the rights and interests of third parties, or in any way that prevents its normal use;</br></br>b) Access or attempt to access restricted resources or areas of the website without meeting the required conditions for such access;</br></br>c) Cause damage to the physical or logical systems of the platform;</br></br>d) Introduce or spread on the platform computer viruses or any other physical or logical systems that may cause damage to it;</br></br>e) Attempt to access, use, and/or manipulate data contained on ehtiq.com, on third parties, or other users;</br></br>f) Copy, distribute, and/or allow public access, through any means of public communication, to the digital and logistical content available on the ehtiq.com platform;</br></br>i) Transmit, disseminate, or make available to third parties written, photographic,sound, and/or image content resulting from interaction with the EHTIQ technical and clinical team.",
          subtitle13: "3 . Compatibility with Various Devices",
          text13:
            "The EHTIQ platform is designed to adapt to various fixed screen sizes and mobile devices, but it does not commit to developing content that fits all devices on the market.",
        },
        experience: {
          box0: "The experience",
          box1: "WE ACCOMPANY</br> YOUR <i>EXPERIENCE</i>",
          box2: "We value every moment. Our commitment is your comfort, ensuring that every step is an expression of care and excellence.",
          box3: "We value every moment.</br> Our commitment is your comfort, ensuring that every step is an expression of care and excellence.",
          box31:
            "We value every moment.</br></br> Our commitment is your comfort, ensuring that every step is an expression of care and excellence.",

          box4: "An Unmatched Reception",
          box5: "Strangers? Just once. We will wait for you at the entrance of the building, so that you can enter our floor accompanied.",
          box6: "A reception without questions or constraints.",
          box7: "Evaluation & Diagnosis",
          box8: "Appreciating what makes you unique,",
          box9: "we design Personalized Treatment Plans based on high-precision diagnostics.",
          box10: "Treatment Sessions",
          box11: "Together at the forefront of Technology,",
          box12:
            "mastering Advanced Technologies that guarantee exceptional results.",
          box13: "Personalized Assistance",
          box14: "All relationships have an end... except ours. ",
          box15:
            "Meet the Personal Assistant compromised with your comfort. From clinical support to a comforting message, we are yours when you need us.",
        },
        admin: {
          sidebar: {
            products: "Products",
            users: "Users",
            analytics: "Analytics",
            blog: "Blog",
            backHome: "Home",
          },
          manageProducts: {
            title: "Manage Products",
            createProduct: "Create Product",
            list: {
              model: "Model",
              movement: "Movement",
              sku: "SKU",
              price: "Price",
              actions: "Actions",
              actionsHints: {
                delete: {
                  tooltip: "Delete this product",
                  title: "Delete product",
                  confirm: "Confirm",
                  reject: "Reject",
                  description:
                    "Are you sure you want to delete this product, this is irreversible",
                },
              },
            },
          },
          createProducts: {
            title: "Manage Products - Create Product",
            editTitle: "Manage Products - Edit Product",
            isCreatingProduct: "The product is being created",
            isLoadingProduct: "The product is being fetched",
            form: {
              required: "Required",
              mustNumber: "Must be a number",
              thumbnail: "Thumbnail",
              fileSize: "The file size should be less than:",
              specialThumbnail: "Special Thumbnail",
              images: "Images",
              model: "Model",

              dimensions: "Dimensions",
              color: "Color",
              description: "Description",
              sku: "SKU",
              submit: "Create Product",
              bracelet: "Bracelet",
              numberWatches: "Number Watches",
              price: "Price",
            },
          },
        },
      },
      tags: {
        concerns: {
          acne: "Acne",

          antiaging: "Anti-Aging",

          bigEars: "Protruding Ears",

          bodyAssimetry: "Body Asymmetry",

          breastLoose: "Sagging Chest",

          breastUneven: "Disproportionate Chest",

          bruxism: "Bruxism",

          cavities: "Cavities",

          cellulite: "Celulite",

          darkCircles: "Dark Circles",

          dilatedPores: "Dilated Pores",

          excessSweat: "Excessive Sweating",

          extraHair: "Excess Hair",

          extraSkin: "Excess Skin",

          fisicalStress: "Stress Físico",

          flacid: "Flaccidity",

          foodIssues: "Food Intolerances",

          general: "General Clinic",

          genvivalSmile: "Gummy Smile",

          intimeZone: "Intimate Area Discomfort",

          localizedFat: "Localized Fat",

          lowFacialVolume: "Loss of Facial Volume",

          lowImmunity: "Low Immunity",

          lowVitamin: "Vitamin Deficiency",

          noseBreathing: "Nasal Breathing Didiculty",

          obstreticConcerns: "Obstetric Follow-up",

          oxidaitionStress: "Oxidative Stress",

          pediatricConcerns: "Pediatric Follow-up",

          postintervention: "Post-surgical Recovery",

          redeness: "Rosacea",

          scars: "Scars",

          skincare: "Skin “Quality",

          smileAssimetry: "Dental Asymmetry",

          spots: "Spots",

          stretchMarks: "Stretch Marks",

          teethColor: "Dental Tonality",

          vains: "Varicose Veins",

          wrinkles: "Wrinkles",
        },
      },
      auth: {
        login: {
          clientArea: "client area",
          email: "Email",
          password: "Password",
          passwordRecover: "Recover password",
          notClient: "Not an EHTIQ client yet?",
          startSession: "Log In",
          createAccount: "Create account",
          welcome: "Welcome"
        },
        recoverPassword: {
          welcome: "Recover here your password",
          subWelcome: "You will receive an email",
          submit: "Recover",
          loaderMessage: "Sending an e-mail",
          insertEmail: "Insert email",
          insertEmailInput: "Insert your account email",
          insertEmailCode: "Insert the code received by email",
          code:"code",
          sentCode: "Send code",
          validateEmail: "Validate email",
          proceed: "Proceed",
          newPassword: "New password",
          recover: "Recover",
        },
        register: {
          newAccount: "new account",
          mandatoryData: "Mandatory",
          optionalData: "Optional",
          loaderMessage: "Creating new user",
          phone: "Phone",
          upload: "Upload",
          photo: "Profile Picture",
          username: "Username*",
          fullName: "Full Name",
          birthday: "Birthday",
          password: "Password*",
          address1: "Address line 1",
          address2: "Address line 2",
          city: "City",
          postalCode: "Postal Code",
          confirmPassword: "Confirm Password*",
          email: "E-mail*",
          register: "Register",
        },
      },
      privateArea: {
        clientArea: "Client Area",
        documents: "Documentos:",
        acceptance: "Consentimentos",
        otherDocuments: "Outros Documentos",
        myAppointments: "Minhas Marcações:",
        nextAppointment: "Próxima Marcação",
        previousAppointments: "Marcações Anteriores",
        assinatura: "Membership:",
        notMember: "Ainda não é membro EHTIQ",
        settings: {
          privateArea: "ÁREA RESERVADA",
          welcome: "Bem-vindo",
          closeSession: "Logout",
          editAccount: "Editar conta",
          website: "Website EHTIQ",
          personalData: "Dados Pessoais",
          completeName: "Nome Completo",
          taxData: "Dados Fiscais",
          address: "Morada",
          taxNumber: "Contribuinte",
          save: "Guardar",
        },
      },

      visitCardFinal: {
        personalAppointments: "In-Person Appointments",
        startHint: "Start by categorizing your consultation.",
        secondHint:
          "We will use your filtering to present scheduling availabilities.",
        filterBy: "I want to filter by:",
        professional: "Professional",
        speciality: "Specialty",
        concern: "Concern",
        startOnlineAppointment: "I wish to schedule an online appointment",
        categorizeAppointments: "Let's categorize your appointment.",
        selectProfessional: "Select the Professional of Interest",
        selectSpeciality: "Select the Specialty of Interest",
        selectConcern: "Select a Concerns Of Interest",
        backButton: "Return to Filter Options",

        bestPerson: "The best hour for the best person.",
        chooseDate: "Choose from the available dates:",
        showMoreDates: "Show other dates",
        confirmationLogin: "Confirmation and Log-In.",
        consultInfo:
          "You can check your appointment and all documents shared by our specialists in your",
        backOptions: "Return to availability options",
        confirm: "Confirm",
        sessionStarted: "Logged-In.",
        hello: "Hello, {{ name }}!",
        changeAccount: "Change Account",
        apointmentDone: "Appointment made!",
        privateArea: "Reserved Area",
      },
    },
  },
};
