export const ROUTE_PATHS = {
  HOME: "/",
  PRODUCTS: "/products",
  PRODUCT: "/product/:product",
  ABOUT: "/about",
  MY_ACCOUNT: "/my-account",
  YOUR_EXPERIENCE: "/your-experience",
  ADMIN_HOME: "/admin",
  ADMIN: "/admin/manage-products",
  ADMIN_MANAGE_DASHBOARD: "/admin/manage-dashboard",

  ADMIN_EDIT_PRODUCT: "/admin/manage-products/edit/:id",
  ADMIN_CREATE_PRODUCT: "/admin/manage-products/create-product",
  ADMIN_MANAGE_USERS: "/admin/manage-users",
  ADMIN_MANAGE_USERS_CREATE: "/admin/manage-users/create",
  ADMIN_MANAGE_CLIENTS: "/admin/manage-clients",
  ADMIN_MANAGE_CONTENT_COLLABS: "/admin/manage-content/collabs",

  ADMIN_MANAGE_CONTENT_BLOG: "/admin/manage-content/blog",
  ADMIN_MANAGE_CONTENT_BLOG_CREATE_NEW: "/admin/manage-content/blog/create-new",
  ADMIN_MANAGE_CONTENT_TREATMENTS: "/admin/manage-content/treatment",
  ADMIN_MANAGE_CONTENT_TREATMENT_CREATE_NEW:
    "/admin/manage-content/treatment/create-new",
  ADMIN_LIVE_CHAT: "/admin/live-chat",
  USER_HOME: "/user",
  USER_DOCUMENTS: "/user/documents",
  USER_SCHEDULLE: "/user/schedulle",
  USER_MEMBERSHIP: "/user/membership",
  LOGIN: "/auth/login",
  REGISTER: "/auth/register",
  PRIVACY_POLICY: "/privacy-policy",
  UTILIZATION_TERMS: "/utilization-terms",
  BUY_SUCCESS: "/success",
  RECOVER_PASSWORD: "/recover-password",

  TERMS_OF_SERVICE: "/terms-of-service",
  REFUND_POLICIE: "/refund-policies",
  MEMBERSHIP: "/membership",
  BLOG_: "/ehtiq-talks",
  BLOG_DETAIL: "/ehtiq-talks/:slug",
  TESTEMONIALS: "/testemonials",
  TREATMENTS: "/treatments",
  TREATMENT_ID: "/treatments/:id",
  COMPLEX: "/the-complex",
  CONTACTS: "/contacts",
  CLINIC1: "/clinic1",
  CLINIC2: "/clinic2",
  CLINIC3: "/clinic3",
  CLINIC4: "/clinic4",
};
