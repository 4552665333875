import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../../../../../../redux/user/actions";
import { userServices } from "../../../../../../../services/user.services";
import { RecoverPassword } from "../../../../../../../types/user";
import { defaultValues } from "./constants";
import { CreateProductSchema, CreateProductSchemaType } from "./validation";
import { useMediaQuery, useTheme } from "@mui/material";
import useCookies from "../../../../../../../hooks/useCookies";
import { useState } from "react";
import { BASE_URL } from "../../../../../../../services/constants";
import useUser from "../../../../../../../hooks/useUser";

const useChangePassword = ({ handleClose }: { handleClose: () => void }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [error, setError] = useState<string | null>(null);
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const { control, handleSubmit } = useForm<CreateProductSchemaType>({
    resolver: zodResolver(CreateProductSchema),
    defaultValues,
  });
  const { setCookie, getCookie } = useCookies();
  const user = useUser();
  const loginUser = async ({
    password,
  }: {
    password: string;
  }) => {
    const baseUrl = BASE_URL;
    const url = `${baseUrl}/password-reset/`;
    const accessToken = getCookie("access");

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: `Token ${accessToken}`,
        },
        body: JSON.stringify({ new_password: password }),
      });

      const body = await response.json();

      if (!response.ok) {
        throw new Error(
          body.detail ||
          "Password reset failed. Please try again."
        );
      }

      return { body };
    } catch (error: any) {
      throw error;
    }
  };

  const { mutate: signInMutation, isLoading: isLogin } = useMutation(
    loginUser,
    {
      onError: (error: any) => {
        console.log(error);
        setError(error.message);
      },
      onSuccess: (data: any) => {
        setError(null);
        handleClose();
      },
      onSettled: () => { },
    }
  );

  const onSubmit: SubmitHandler<CreateProductSchemaType> = async (formData) => {
    const { password, confirmPassword } = formData;

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    signInMutation({ password });
  };

  return {
    handleSubmit,
    onSubmit,
    control,
    navigate,
    isLogin,
    mobile,

    error,
  };
};

export default useChangePassword;
