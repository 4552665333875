import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import { BASE_URL } from "../../../../../../../services/constants";
import useCookies from "../../../../../../../hooks/useCookies";
import { CreateProductSchema, CreateProductSchemaType } from "./validation";

const useInputToken = ({
  handleClose,
  onSuccess,
}: {
  handleClose: () => void;
  onSuccess: (email: string, token: string) => void;
}) => {
  const [error, setError] = useState<string | null>(null);

  const { setCookie } = useCookies();

  const { control, handleSubmit } = useForm<CreateProductSchemaType>({
    resolver: zodResolver(CreateProductSchema),
    defaultValues: {
      token: "",
    },
  });

  const loginUser = async ({ token }: { token: string }) => {
    const response = await fetch(`${BASE_URL}/check-token/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    });

    if (!response.ok) {
      const body = await response.json();
      throw new Error(body.detail || "Failed to validate the token.");
    }

    const body = await response.json();
    return { email: body.Data.email, token: body.Data.token };
  };

  const { mutate: signInMutation, isLoading: isLogin } = useMutation(loginUser, {
    onError: (error: Error) => {
      setError(error.message);
    },
    onSuccess: (data: { email: string; token: string }) => {
      setError(null);
      setCookie("access", data.token);
      onSuccess(data.email, data.token);
    },
  });

  const onSubmit: SubmitHandler<CreateProductSchemaType> = (formData) => {
    signInMutation(formData);
  };

  return {
    handleSubmit,
    onSubmit,
    control,
    isLogin,
    error,
  };
};

export default useInputToken;
