export const queryIdentifiers = {
  USER: "USER",
  PRODUCTS: "PRODUCTS",
  PRODUCT: "PRODUCT",
};

export const DB = {
  PRODUCTS: "products",
  USERS: "users",
};

var stringValue = process.env.REACT_APP_DEV_MODE
var boolValue = (stringValue == "true"); 

//export const BASE_URL = "https://clinicasritz-be-staging.qloudyx.pt";
export const BASE_URL = process.env.REACT_APP_BASE_URL
export const DEV_MODE = boolValue
//export const BASE_URL =
// "https://8c47-2001-818-d83c-1600-68a8-b4b0-b973-e179.ngrok-free.app";
