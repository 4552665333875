import { z } from "zod";
import { i18n } from "../../../../../../../translations/i18n";

export const CreateProductSchema = z.object({
  password: z
    .string(),
  confirmPassword: z
    .string()
});

export type CreateProductSchemaType = z.infer<typeof CreateProductSchema>;
