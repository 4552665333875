import { useState, useEffect } from "react"
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";
import { theme } from "./theme/theme";
import { useDetectLanguage } from "./hooks/useInitialLang";
import ReactGA from "react-ga4"; 

function App() {
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 60 * 1000
      },
    },
  }));
  
  useDetectLanguage();

  
useEffect(() => {
  if (process.env.REACT_APP_GA_TRACKING_ID !== undefined && process.env.REACT_APP_GA_TRACKING_ID !== ""){
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
  }
        
},[])
  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              {/* <CookiePolicy /> */}
              {/* <div style={{ height: "100vh" }}>
                <Scroll /> */}
              <AppRoutes />
              {/* </div> */}
            </QueryClientProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;
