import { Box } from "@mui/material";
import Status from "../../../../assets/status-up.svg";
import Manage from "../../../../assets/share.svg";
import Settings from "../../../../assets/setting-5.svg";
import UserIcon from "../../../../assets/user-octagon.svg";
import Callendar from "../../../../assets/calendar.svg";
import Scope from "../../../../assets/Group 5.svg";
import Chat from "../../../../assets/device-message.svg";
import EhtiqLogo from "../../../../assets/EHTIQ BRANDS-09.svg";
import GestaoClinica from "../../../../assets/IDcard_fill.svg";
import { DEV_MODE } from "../../../../services/constants";

import { ROUTE_PATHS } from "../../../../routes/constants";
import Close from "../../../../assets/Power.png";
import { i18n } from "../../../../translations/i18n";
import Button from "./Button";
import User from "./User";
import { useNavigate } from "react-router-dom";

const AdminSideBar = () => {
  const navigate = useNavigate();
  return (
    <Box
      style={{
        position: "relative",
        height: "100vh",
        background: "rgba(5, 4, 4, 0.85)",
        borderRadius: "0px 10px 10px 0px",
        padding: "20px",
        transition: "all 0.5s ease-in-out",
        backdropFilter: "blur(100px)",
        WebkitBackdropFilter: "blur(100px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* User Section */}
      <Box
        style={{
          position: "fixed",
          top: "50px",
          left: "20px",
          right: "20px",
          zIndex: 10,
        }}
      >
        <User />
      </Box>

      <Box
        style={{
          marginTop: "120px",
          overflowY: "auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p
          style={{
            marginBottom: "10px",
            marginLeft: "20px",
            textTransform: "capitalize",
            fontSize: "11px",
            letterSpacing: "0.5px",
            color: "rgba(255, 255, 255, 0.32)",
          }}
        >
          Menu
        </p>
        <Button
          label={"Performance"}
          icon={<img src={Status} style={{ height: "24px" }} alt="" />}
          paths={[
            { path: ROUTE_PATHS.ADMIN_MANAGE_DASHBOARD, label: "Dashboard" },
          ]}
        />
        <Button
          label={i18n.t("Gestão Conteúdos")}
          icon={<img src={Manage} style={{ height: "24px" }} alt="" />}
          paths={[
            {
              path: ROUTE_PATHS.ADMIN_MANAGE_CONTENT_TREATMENTS,
              label: "PIT",
            },
            {
              path: ROUTE_PATHS.ADMIN_MANAGE_CONTENT_BLOG,
              label: "BLOG",
            },
          ]}
        />
        <Button
          label={i18n.t("Utilizadores")}
          icon={<img src={Settings} style={{ height: "24px" }} alt="" />}
          paths={[{ path: ROUTE_PATHS.ADMIN_MANAGE_USERS, label: "Users" }]}
        />
        {DEV_MODE && (
          <Button
            label={i18n.t("Clientes")}
            icon={<img src={UserIcon} style={{ height: "24px" }} alt="" />}
            paths={[
              { path: ROUTE_PATHS.ADMIN_MANAGE_CLIENTS, label: "Clients" },
            ]}
          />
        )}
        {DEV_MODE && (
          <Button
            label={i18n.t("Agenda")}
            icon={<img src={Callendar} style={{ height: "24px" }} alt="" />}
            paths={[{ path: ROUTE_PATHS.HOME, label: "Agenda" }]}
          />
        )}
        {DEV_MODE && (
          <Button
            label={i18n.t("Gestão Clínica")}
            icon={<img src={GestaoClinica} style={{ height: "24px" }} alt="" />}
            paths={[{ path: ROUTE_PATHS.HOME, label: "Gestão Clínica" }]}
          />
        )}
        {DEV_MODE && (
          <Button
            label={i18n.t("EXPERT ADVICE")}
            icon={<img src={Scope} style={{ height: "24px" }} alt="" />}
            paths={[{ path: ROUTE_PATHS.HOME, label: "Expert Advice" }]}
          />
        )}
        {DEV_MODE && (
          <Button
            label={i18n.t("Live-Chat")}
            icon={<img src={Chat} style={{ height: "24px" }} alt="" />}
            paths={[{ path: ROUTE_PATHS.ADMIN_LIVE_CHAT, label: "Live-chat" }]}
          />
        )}
      </Box>

      <Box
        style={{
          position: "fixed",
          bottom: "50px",
          left: "20px",
          right: "20px",
          zIndex: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={EhtiqLogo} style={{}} alt="Ehtiq Logo" />
        <Box
          onClick={() => navigate(ROUTE_PATHS.HOME)}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            columnGap: "6px",
          }}
        >
          <img src={Close} style={{ height: "24px" }} alt="Logout" />
          <p style={{ color: "#C3C3C3", fontSize: "12px" }}>Logout</p>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminSideBar;
