export const content = {
  es: {
    translations: {
      comingSoon: "Próximamente",
      header: {
        home: "Inicio",

        clinic: "La clínica",

        treatments: "Tratamientos",

        experience: "La experiencia",

        blog: "Blog EHTIQ",

        about: "Acerca de EHTIQ",

        contacts: "Contactos",

        membership: "Miembros",

        privacyPolicy: "Política de Privacidad",

        privacyCookies: "Política de Cookies",

        account: "Mi cuenta",

        login: "?",

        logout: "Cerrar sesión",

        admin: "?",
      },

      footer: {
        schedulle: "Cita prévia",

        contact: "Contacta con nosotros",

        ourPolicies: "Nuestras Políticas",

        registerConcern: "Registrar una Reclamación",

        copyright: "Copyright 2024. Desenrrolado por LINEA AGENCY.",

        privacyPolicy: "Privacidad e Cookies",

        privacyCookies: "Condiciones de Uso",
      },

      expertAdvice: {
        box0: "Búsqueda Inteligente",

        box1: "Elige un filtro",

        box2: "Especialidades</br> EHTIQ",

        box21: "Especialidades EHTIQ",

        box3: "Zonas </br> Corporales",

        box31: "Zonas Corporales",

        box4: "Preocupaciones</br> A-Z",

        box41: "Preocupaciones A-Z",

        box5: "Volver a filtros principales",

        box6: "Reempezar",
      },

      pages: {
        identityBox: {
          box1: "Somos Especialistas En",

          box2: "Protección de Identidad",

          box3: "Confidencialidad",

          box4: "Privacidad",

          box5: "Protección de Datos",

          box6: "Pedir Cita",

          button1: "Contactar",
        },

        about: {
          box0: "Acerca de EHTIQ",

          box1: "Nuestra promesa</br> de bienestar",

          box2: "La eterna búsqueda humana del bienestar es una misión que abarca la historia de nuestra existencia.</br></br>Es la búsqueda de un estado óptimo de salud y bienestar, no sólo físico, sino también emocional y espiritual.",

          box3: "",

          box4: "Situado en una de las zonas más nobles de Lisboa, EHTIQ es un símbolo de excelencia y tradición.",

          box5: "Descubra el espacio diseñado para superar sus expectativas, promoviendo la máxima calidad en la asistencia sanitaria.",

          box6: "Elevamos los <b> estándares de calidad</b> en la asistencia sanitaria .",

          box7: "VALORES QUE REPRESENTAN </br> LA EXCELENCIA",

          box8: "Elegancia",

          box9: "Armonía",

          box10: "Tranquilidad",

          box11: "Innovación",

          box12: "Calidad",

          box13: "La Experiencia",

          box14:
            "Entra en un universo cuidadosamente creado para brindar una experiencia única.</br></br><b class='custom-bold'>Equipos especializados, un enfoque científico</b> y una orientación hacia la experiencia son nuestros compromisos.",

          button1: "Entrar",
        },

        complex: {
          title:
            "Elegancia e innovación, donde cada espacio es una celebración de la estética.",

          eyes: "Ojos Puestos",

          in: "en la",

          persona: "Personalización",

          experience: "La Experiencia",

          mainText:
            "En EHTIQ, la personalización es la esencia de nuestra filosofía. Moldeamos cada tratamiento para reflejar tus aspiraciones.",

          secondText:
            "Creemos que la belleza es un viaje individual, hecho a medida para para resaltar tu singularidad.",

          teamTitle: "?",

          teamSubtitle: "?",
        },

        home: {
          text1: "Fusión de Belleza y Bienestar",

          text2:
            "Un espacio de sofisticación y autodescubrimiento que te lleva de viaje por un horizonte de belleza y bienestar.",

          box31: "Estética y </br> Cirugía",

          box32: "Dental",

          box33: "Postquirúrgica </br> y Bienestar",

          box34: "Salud Preventiva",

          box41: "Búsqueda inteligente",

          box42: "Encuentra los tratamientos más adecuados para ti.",

          box61: "Servicios de Conserjería",

          box62: "Descubre el",

          box622: "Patrón",

          box623: "EHTIQ",

          box624: "Destacado",

          box63:
            "El Servicio de Conserjería está diseñado para ofrecer la máxima comodidad, eliminando preocupaciones logísticas y optimizando tu tiempo. </br> Desde la citas prévia al acceso prioritario a especialistas y tratamientos, nuestro equipo de Conserjería está a tu disposición para garantizar que tu visita sea cómoda y sin complicaciones.",

          button0: "Expert Advice",

          button1: "Solicitar Conserjería",

          button2: "La Clínica",

          icon1: "Hotel",

          icon2: "Viaje",

          icon3: "Transporte",

          icon4: "Conserjería",

          box71: "Conoce el espacio donde te cuidamos.",

          admin: {
            sidebar: {
              products: "Produtos",

              backHome: "Home",

              analytics: "Métricas",

              blog: "Blog",

              users: "Utilizadores",
            },

            manageProducts: {
              title: "Manage Products",

              createProduct: "Create Product",

              list: {
                model: "Model",

                movement: "Movement",

                sku: "SKU",

                price: "Price",

                actions: "Actions",

                actionsHints: {
                  delete: {
                    tooltip: "Delete this product",

                    title: "Delete product",

                    confirm: "Confirm",

                    reject: "Reject",

                    description:
                      "Are you sure you want to delete this product, this is irreversible",
                  },
                },
              },
            },

            createProducts: {
              title: "Manage Products - Create Product",

              editTitle: "Manage Products - Edit Product",

              isCreatingProduct: "The product is being created",

              isLoadingProduct: "The product is being fetched",

              form: {
                required: "Required",

                mustNumber: "Must be a number",

                thumbnail: "Thumbnail",

                fileSize: "The file size should be less than:",

                specialThumbnail: "Special Thumbnail",

                images: "Images",

                model: "Model",

                dimensions: "Dimensions",

                color: "Color",

                description: "Description",

                sku: "SKU",

                submit: "Create Product",

                bracelet: "Bracelet",

                numberWatches: "Number Watches",

                price: "Price",
              },
            },
          },

          auth: {
            login: {
              welcome: "Enter in the world of Favre-Bulle",

              subWelcome: "Login to your account",

              loaderMessage: "Logging in",

              username: "Username",

              password: "Password",

              login: "Login",

              newUser: "New User",

              register: "Not registered yet? Click here",
            },

            register: {
              loaderMessage: "Creating new user",

              phone: "Phone",

              username: "Username",

              fullName: "Full Name",

              birthday: "Birthday",

              password: "Password",

              address1: "Address line 1",

              address2: "Address line 2",

              city: "City",

              postalCode: "Postal Code",

              confirmPassword: "Confirm Password",

              email: "E-mail",

              register: "Register",
            },
          },
        },

        schedulleBox: {
          box1: "Cita Prévia",

          box2: "Pide cita online o pide una cita con nuestros asistentes.",

          box3: "Miembros",

          box4: "Obtenga beneficios exclusivos en el Acceso de Miembros.",

          button1: "Abrir Agenda",

          button2: "Próximamente",
        },

        clinic: {
          box11: "Pasión por el detalle",

          box12:
            "Elementos de arquitectura moderna, tonos elegantes e iluminación cálida.",

          box13: "El Bar EHTIQ",

          box14:
            "Creado para proporcionar los mejores momentos de espera en compañía de nuestro equipo.",

          box15: "Postoperatorio y Bienestar",

          box16:
            "Serenidad aportada por elementos acogedores y gran atención al detalle.",

          box17: "Sala de Espera Nany Mota",

          box18: "Sabemos que no hay otro lugar donde quieras esperarnos.",

          box21:
            "Elegancia e innovación, donde cada espacio es una.</br> celebración de la estética.",

          box31:
            "Sala de espera Postquirúrgica y de Bienestar by </br> Nany Mota",
          box312: "Sala de espera Postquirúrgica y de Bienestar by Nany Mota",

          box32: "Acceso a las Unidades Dental y </br> Postquirúrgica",

          box33: "Zonas privadas con acabados de primera calidad",

          box41: "Ojos Puestos",

          box412: "En La",

          box413: "Personalización",

          box42:
            "En EHTIQ, la personalización es la esencia de nuestra filosofía. Moldeamos cada tratamiento para reflejar tus aspiraciones.",

          box43:
            "Creemos que la belleza es un viaje individual, hecho a medida para para resaltar tu singularidad.",

          button1: "La Experiencia",
        },

        treatments: {
          box01: "Tratamientos",

          box0: "Exclusivos",

          box1: "Estética y Cirugía",

          box2: "Hemos combinado los mejores profesionales con la mejor tecnología del mercado para garantizar resultados excepcionales y el más alto nivel de atención clínica.",

          box3: "LIPO DE ALTA DEFINICIÓN",

          box4: "Un enfoque innovador y exclusivo que permite enfatizar los contornos musculares y resaltar sus detalles más finos, dejando la Liposucción convencional en el pasado.",

          box5: "EHTIQ ELIXIR",

          box6: "Diseñado para contrarrestar los signos del tiempo devolviendo firmeza, elasticidad y luminosidad. Un cóctel de potentes ingredientes, formulado para diferentes zonas del cuerpo.",

          box7: "EHTIQ AGELESS Cuello y Escote",

          box8: "Tecnología punta combinada con las mejores fórmulas del mercado. Un protocolo antiedad con resultados inigualables.",

          box9: "Postoperatorio y Bienestar",

          box10:
            "Cuidados postoperatorios con la Técnica Nany Mota® y protocolos personalizados para garantizar el bienestar del organismo. Protocolos personalizados para garantizar el bienestar del cuerpo y la mente.",

          box11: "MASAJE POSTQUIRÚRGICO BY NANY MOTA",

          box12:
            "Un referente en el cuidado postquirúrgico, con la exclusiva técnica Nany Mota®.",

          box13: "GLASS SKIN BY HYDRAFACIAL",

          box14:
            "La combinación de limpieza, exfoliación, extracción e hidratación en un único proceso, gracias a la tecnología HydraFacial.",

          box15: "Salud Preventiva",

          box16:
            "La revolución de la práctica clínica centrada en el equilibrio físico, mental y emocional. Hemos pasado del concepto intervencionista al de longevidad.",

          box17: "SUSTITUCIÓN HORMONAL",

          box18:
            "La poderosa ayuda contra la fatiga, los cambios de humor y el aumento de peso, con protocolos personalizados..",

          box19: "REPOSICIÓN VITAMÍNICA IV DRIPS",

          box20:
            "Logrado con nutrientes esenciales que fluyen directamente en el sistema, revitalizando tu cuerpo desde el interior.",

          box21: "Dental",

          box22:
            "Especializada en carillas dentales hechas a mano, la Unidad Dental EHTIQ se guía por altos estándares de orgullo y sensibilidad.",

          box23: "CARILLAS DE ÚLTIMA GENERACIÓN",

          box24:
            "Finas carillas de cerámica o resina compuesta, hechas a mano para un efecto muy natural.",

          box25: "ORTODONCIA INVISIBLE",

          box26:
            "Alineadores imperceptibles que garantizan la comodidad y mantienen la estética de tu sonrisa durante todo el proceso..",

          box27: "Selección de Servicios",

          box28: "Catálogo EHTIQ",

          box29: "POR ESPECIALIDAD",

          box30: "POR ZONAS CORPORALES",
        },

        experience: {
          box0: "La Experiencia",

          box1: "ACOMPAÑAMOS TU</br> <i> EXPERIENCIA</i>",

          box2: "Valoramos cada momento. Nuestro compromiso es con tu comodidad, garantizando que cada paso sea una expresión de cuidado y excelencia.",

          box3: "",

          box31: "",

          box4: "Una Bienvenida Inigualable",

          box5: "Ven a conoceros, te esperamos en el edificio, para que puedas pasar acompañado a nuestra clínica.",

          box6: "Una bienvenida sin preguntas ni limitaciones.",

          box7: "Evaluación y Diagnóstico",

          box8: "Apreciamos lo que te hace único.",

          box9: " Diseñamos planes de tratamiento personalizados, basados en diagnósticos de alta precisión.",

          box10: "Sesiones de Tratamiento",

          box11: "Estamos a la vanguardia de la tecnología, junto contigo.",

          box12:
            "Utilizamos tecnologías avanzadas que garantizan resultados excepcionales.",

          box13: "Seguimiento Personalizado",

          box14: "Todas las relaciones llegan a su fin... excepto la nuestra.",

          box15:
            "Conozca al Asistente Personal que se encarga de cuidarte. De apoyo clínico a mensaje reconfortante. Somos tuyos cuando nos necesitas.",
        },

        body: {
          box1: "Cara",

          box2: "Sonrisa",

          box3: "Corporal",

          box4: "Salud 360",
        },
        privacyPolicy: {
          title: "Política de Privacidad",
          subtitle1: "Compromiso con la Protección de Datos y Privacidad",
          text1:
            "EHTIQ y sus elementos internos directamente relacionados con ella se comprometen a mantener el secreto y la confidencialidad en el tratamiento de los datos personales de los cuales son responsables, de acuerdo con esta Política de Privacidad.",
          subtitle2: "Entidad Responsable y Contacto",
          text2:
            "Elevate Plures Investments, Lda, con sede en Avenida Duque de Loulé, 24 - 7º Esq. - 1050-090 Lisboa, entidad corporativa nº 516677373, y propietaria de la marca EHTIQ, es la entidad responsable del tratamiento de los datos personales contenidos y gestionados en www.ehtiq.com y sus subpáginas (en adelante denominada área digital EHTIQ o plataforma), ya sea directamente o a través de aplicaciones informatizadas contenidas en ella, a través de las cuales los Usuarios tienen acceso remoto a sus servicios y productos, ya sean presentados, comercializados o proporcionados en cualquier momento a través de su plataforma.</br></br>Cualquier aclaración o solicitud relacionada con esta Política de Privacidad puede ser enviada a info@ehtiq.com, acompañada de un contacto directo, nombre y apellido, y/u otros datos personales relevantes para el asunto descrito.",
          subtitle3: "Datos Personales Tratados",
          text3:
            "EHTIQ declara tratar los siguientes datos personales principales, entre otros que puedan ser necesarios y que se recojan legítimamente:</br></br>- Nombre completo</br></br>- Email</br></br>- Fecha de nacimiento</br></br>- Número de identificación fiscal</br></br>- Número de identificación civil</br></br>- Estado civil</br></br>- Género</br></br>- Lugar de nacimiento</br></br>- Dirección (calle, código postal, ciudad, país)</br></br>- Redes sociales públicas",
          subtitle4: "Finalidad del Tratamiento de Datos",
          text4:
            "Los datos personales tratados en el área digital de EHTIQ están destinados a:</br></br>- Proporcionar información directamente al usuario</br></br>- Gestión considerada necesaria para la comunicación interna y/o comunicación con el usuario correspondiente</br></br>- Prestación de servicios apoyados por proveedores cuyo tratamiento de datos y garantías de confidencialidad estén debidamente expresados y parezcan cumplirse estrictamente</br></br>- Acciones de divulgación de información o promocionales con fines comerciales o de marketing, como la divulgación de nuevos productos y servicios a través de comunicación directa, siempre que esto sea consentido",
          subtitle5: "Responsabilidad del Usuario",
          text5:
            "Es responsabilidad exclusiva del Usuario mantener la confidencialidad de sus credenciales de acceso a la plataforma, no compartiéndolas con terceros. Además, debe mantener sus dispositivos de acceso seguros y seguir las prácticas recomendadas por los fabricantes y/o operadores, incluyendo la instalación y actualización de aplicaciones de seguridad necesarias, como software antivirus.",

          subtitle6: "Consentimiento Expreso",
          text6:
            "El suministro libre, específico e informado de los datos personales por parte de su titular implica el conocimiento y aceptación de esta Política y constituye una autorización expresa para su tratamiento de acuerdo con las prácticas definidas anteriormente.",
          title2: "Política de Cookies",
          subtitle7: "Uso de Cookies",
          text7:
            "El área <a>www.ehtiq.com</a> y sus subpáginas utilizan cookies para mejorar la experiencia del usuario. A continuación, explicamos cómo lo hacemos.",
          subtitle8: "¿Qué son las Cookies?",
          text8:
            "Las cookies son pequeños archivos que se almacenan en su computadora o dispositivo móvil a través de su navegador (navegador de Internet). Permiten monitorear parte de su actividad en una plataforma específica para optimizar su experiencia de uso.</br></br>Su uso en la web es común y no daña su hardware ni su software.",
          subtitle9: "Finalidad de Nuestras Cookies",
          text9:
            "Nuestras cookies recogen información genérica, como la forma en que el usuario llegó a nuestro sitio, el país desde el que acceden, entre otros, reteniendo solo información relacionada con sus preferencias y patrones de uso. Las cookies que utilizamos pertenecen a dos categorías:</br></br><b>Permanentes</b> – Almacenadas a nivel del navegador en sus dispositivos de acceso (PC, móvil y tablet) y utilizadas siempre que realice una nueva visita al área digital EHTIQ.</br></br><b>Cookies de sesión </b>– Temporales y que permanecen en el archivo de cookies de su navegador hasta que abandone el área digital EHTIQ. La información obtenida por estas cookies se utiliza para analizar patrones de tráfico web, permitiéndonos identificar problemas y proporcionar una mejor experiencia de navegación.",
          subtitle10: "Gestión de Cookies por el Usuario",
          text10:
            "En cualquier momento, el usuario puede verificar qué cookies tiene en su computadora, eliminarlas o bloquear su acceso. Todo esto se hace directamente en su navegador de Internet. Si bloquea nuestras cookies, puede tener dificultades para acceder a algunas áreas del sitio.",
          title3: "Términos de Uso",
          subtitle11: "1 . Conformidad con las Políticas",
          text11:
            "Al utilizar el presente área digital EHTIQ, el usuario acepta las Políticas de Privacidad y Políticas de Cookies comunicadas en ella.",
          subtitle12: "2 . Condiciones de Uso",
          text12:
            "El visitante y/o usuario del área digital EHTIQ se compromete al uso adecuado y lícito de su plataforma, así como de los contenidos y servicios, de acuerdo con los presentes Términos de Uso, y no podrá:</br></br>a) Hacer un uso no autorizado o fraudulento de la plataforma para fines ilícitos, dañinos para los derechos e intereses de terceros, o que de cualquier manera impidan su uso normal;</br></br>b) Acceder o intentar acceder a recursos o áreas restringidas del sitio web, sin cumplir con las condiciones exigidas para dicho acceso;</br></br>c) Provocar daños en los sistemas físicos o lógicos de la plataforma;</br></br>d) Introducir o difundir en la plataforma virus informáticos u otros sistemas físicos o lógicos susceptibles de causar daños en la misma;</br></br>e) Intentar acceder, usar y/o manipular los datos contenidos en ehtiq.com, em terceros, o en otros usuarios;</br></br>f) Copiar, distribuir y/o permitir el acceso público, a través de cualquier modalidade de comunicación pública, al contenido digital y logístico disponible en la plataforma ehtiq.com;</br></br>i) Transmitir, difundir o poner a disposición de terceros contenido escrito, fotográfico, de sonido y/o imagen, resultante de la interacción con el equipo técnico y clínico EHTIQ.",
          subtitle13: "3 . Compatibilidad con Diversos Dispositivos",
          text13:
            "La plataforma EHTIQ está desarrollada con el propósito de adaptarse a diversos tamaños de pantalla fija y dispositivos móviles, no comprometiéndose a desarrollar contenidos que se ajusten a todos los dispositivos existentes en el mercado.",
        },
      },

      tags: {
        concerns: {
          acne: "Acne",

          antiaging: "Anti-envelhecimento",

          bigEars: "Orelhas Proeminentes",

          bodyAssimetry: "Assimetria Corporal",

          breastLoose: "Peito Descaído",

          breastUneven: "Peito Desproportional",

          bruxism: "Bruxismo",

          cavities: "Cáries",

          cellulite: "Celulite",

          darkCircles: "Olheiras",

          dilatedPores: "Poros Dilatados",

          excessSweat: "Sudorese Excessiva",

          extraHair: "Excesso de Pelos",

          extraSkin: "Excesso de Pele",

          fisicalStress: "Stress Físico",

          flacid: "Flacidez",

          foodIssues: "Intolerâncias Alimentares",

          general: "Clínica Geral",

          genvivalSmile: "Sorriso Gengival",

          intimeZone: "Desconforto Zona Íntima ",

          localizedFat: "Gordura Localizada",

          lowFacialVolume: "Perda de Volume Facial",

          lowImmunity: "Baixa Imunidade",

          lowVitamin: "Carência Vitamínica",

          noseBreathing: "Dificuldade Respiratória Nasal",

          obstreticConcerns: "Acompanhamento Obstétrico",

          oxidaitionStress: "Stress Oxidativo",

          pediatricConcerns: "Acompanhamento Pediátrico",

          postintervention: "Recuperação Pós-operatória",

          redeness: "Rosácea",

          scars: "Cicatrizes",

          skincare: "Qualidade da Pele",

          smileAssimetry: "Assimetria Dentária",

          spots: "Manchas",

          stretchMarks: "Estrias",

          teethColor: "Tonalidade Dentária Indesejada",

          vains: "Varizes",

          wrinkles: "Rugas",
        },
      },
      auth: {
        login: {
          clientArea: "area clientes",

          email: "Correo electrónico",

          password: "Contraseña",

          passwordRecover: "Recuperar contraseña",

          startSession: "Conectarse",

          notClient: "¿Aún no es cliente de EHTIQ?",

          createAccount: "Crear cuenta",

          welcome: "Bienvenido"
        },

        recoverPassword: {
          recoverPassword: "Recuperar contraseña",

          insertEmail: "Introducir correo electrónico",

          insertEmailInput:
            "Introduzca la dirección de correo electrónico asociada a su cuenta",

          sentCode: "Enviar código",

          startSession: "Conectarse",

          validateEmail: "Validar correo electrónico",

          insertEmailCode:
            "Introduzca el código que ha recibido por correo electrónico",

          proceed: "Siguiente",

          newPassword: "Nueva contraseña",

          definePassword: "Establecer contraseña",

          confirmPassword: "Confirmar contraseña",

          recover: "Recuperar",

          code: "Código"
        },

        register: {
          newAccount: "nueva cuenta",

          mandatoryData: "Datos obligatorios",

          name: "Nombre y apellidos",

          email: "Dirección de correo electrónico",

          password: "Establezca su contraseña",

          confirmPassword: "Confirme su contraseña",

          optionalData: "Información opcional",

          telephone: "Número de teléfono",

          photo: "Foto de perfil",

          upload: "Cargar",

          register: "Registro",
        },
      },
      privateArea: {
        clientArea: "Área de Clientes",

        documents: "Documentos:",

        acceptance: "Consentimentos",

        otherDocuments: "Outros Documentos",

        myAppointments: "Minhas Marcações:",

        nextAppointment: "Próxima Marcação",

        previousAppointments: "Marcações Anteriores",

        assinatura: "Membership:",

        notMember: "Ainda não é membro EHTIQ",

        settings: {
          privateArea: "ÁREA RESERVADA",

          welcome: "Bienvenido",

          closeSession: "Cerrar Sesión",

          editAccount: "Editar cuenta",

          website: "Website EHTIQ",

          personalData: "Datos personales",

          completeName: "Nombre y apellidos",

          taxData: "Datos Fiscales",

          address: "Dirección",

          taxNumber: "NIF/ DNI",

          save: "Guardar",
        },
      },

      visitCard: {
        box1: "Encuéntranos",

        box11: "Contáctenos",

        box2: "Reserve",

        box3: "Un momento brillante",

        box4: "Contáctanos para reservar tu cita.</br> Nos vemos pronto",
      },

      visitCardFinal: {
        personalAppointments: "Citas Presenciales",

        startHint: "Empiece por clasificar su cita.",

        secondHint:
          "Utilizaremos tu filtro para mostrar la disponibilidad de citas.",

        filterBy: "Quiero filtrar por:",

        professional: "Tratamientos",

        speciality: "Especialidade",

        concern: "Preocupaciones",

        startOnlineAppointment: "Quiero pedir cita online",

        categorizeAppointments: "Vamos a clasificar su cita",

        selectProfessional: "Seleccione el Profesional de Interés",

        selectSpeciality: "Seleccione la Especialidad de Interés",

        selectConcern: "Seleccione la Preocupación de Interés:",

        backButton: "Volver a Opciones de filtrado",

        bestPerson: " El mejor momento para la mejor persona.",

        chooseDate: "Elija entre las fechas disponibles:",

        showMoreDates: "Mostrar otros horarios",

        confirmationLogin: "Confirmación e inicio de sesión.",

        consultInfo:
          "Puede consultar su reserva y todos los documentos compartidos por nuestros expertos en su",

        backOptions: "Volver a las opciones de disponibilidad",

        confirm: "Confirmación",

        sessionStarted: "Sesión iniciada.",

        hello: "¡Hola,  {{ name }}!",

        changeAccount: "Cambiar cuenta",

        apointmentDone: "¡Reserva efectuada!",

        privateArea: "Área Reservada",
      },
    },
  },
};
