import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSignOut } from "../../../../hooks/useLogout";
import { State } from "../../../../redux/types";

import { CurrentUser } from "../../../../types/user";
import Login from "./Auth/Login";
import RecoverPassword from "./Auth/RecoverPassword";
import InputToken from "./Auth/RecoverPassword/InputToken";
import ChangePassword from "./Auth/RecoverPassword/ChangePassword";
import Register from "./Auth/Register";
import { Typography, useMediaQuery, useTheme } from "@mui/material";

const LoginPopoverContent = ({ handleClose, visitCard }: any) => {
  const navigate = useNavigate();
  const currentUser = useSelector<State, CurrentUser | null>(
    (state) => state.user.currentUser
  );
  const { onSignOut } = useSignOut();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const [mode, setMode] = useState<"login" | "register" | "recover" | "token" | "change">("login");
  const [email, setEmail] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const handleTokenSuccess = (receivedEmail: string, receivedToken: string) => {
    setEmail(receivedEmail);
    setToken(receivedToken);
    setMode("change");
  };

  const render = () => {
    switch (mode) {
      case "login":
        return (
          <Login
            visitCard={visitCard}
            setMode={setMode}
            handleClose={handleClose}
          />
        );
      case "register":
        return (
          <Register
            visitCard={visitCard}
            setMode={setMode}
            handleClose={handleClose}
          />
        );
      case "recover":
        return <RecoverPassword
          visitCard={visitCard}
          setMode={setMode}
          handleClose={handleClose}
        />;
      case "token":
        return <InputToken
          visitCard={visitCard}
          setMode={setMode}
          handleClose={handleClose}
          onSuccess={(email: string, token: string) => handleTokenSuccess(email, token)}
        />;
      case "change":
        if (!email || !token) {
          return <Typography>Error: Missing email or token!</Typography>;
        }
        return (
          <ChangePassword
            setMode={setMode}
            handleClose={handleClose}
            email={email}
            token={token}
          />
        );
      default:
        return <Login setMode={setMode} handleClose={handleClose} />;
    }
  };

  return (
    <div
      style={{
        width: mobile ? "auto" : visitCard ? "325px" : "400px",
      }}
    >
      {render()}
    </div>
  );
};

export default LoginPopoverContent;
