import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Icons } from "../../../../../../../components/Icons";
import ControlledFormInput from "../../../../../../../components/Inputs/ControlledInputAdmin";
import { i18n } from "../../../../../../../translations/i18n";
import useInputToken from "./useInputToken";
import ButtonBlue from "../../../../../../../components/Ui/ButtonBlue";
import { useEffect } from "react";


const InputToken = ({
  setMode,
  handleClose,
  visitCard,
  onSuccess,
}: {
  setMode: React.Dispatch<React.SetStateAction<"login" | "register" | "recover" | "token" | "change">>;
  handleClose: () => void;
  visitCard: boolean;
  onSuccess: (email: string, token: string) => void;
}) => {
  const { handleSubmit, onSubmit, control, error, isLogin } = useInputToken({
    handleClose,
    onSuccess,
  });
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleFormSubmit = async (data: { token: string }) => {
    await onSubmit(data);
    setMode("change");
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)} style={{ marginTop: "10px" }}>
        <Box
          style={{
            padding: mobile
              ? "40px 40px 40px 40px"
              : visitCard
                ? "70px 40px 40px 40px"
                : "80px 65px 40px 65px",
            position: "relative",
          }}
        >
          <div
            onClick={() => setMode("login")}
            style={{
              position: "absolute",
              top: "30px",
              left: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              columnGap: "10px",
              cursor: "pointer",
            }}
          >
            <Icons.LeftArrow />
            <p
              style={{
                textTransform: "uppercase",
                textDecoration: "underline",
                fontSize: "10px",
                fontFamily: "Inter",
                fontWeight: 400,
                letterSpacing: "1px",
              }}
            >
              {i18n.t("auth.login.startSession")}
            </p>
          </div>
          <p
            style={{
              fontSize: visitCard ? "15px" : "20px",
              fontWeight: 600,
              textAlign: "center",
              letterSpacing: "1px",
              fontFamily: "Inter",
              textTransform: "capitalize",
            }}
          >
            {i18n.t("auth.login.passwordRecover").toUpperCase()}
          </p>
          <p
            style={{
              height: "28px",
              fontSize: "11px",
              fontWeight: 400,
              fontFamily: "Inter",
              textTransform: "uppercase",
              textAlign: "center",
              alignContent: "center"
            }}
          >
            {i18n.t("auth.recoverPassword.validateEmail")}
          </p>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <p
                style={{
                  marginTop: "30px",
                  fontSize: "7px",
                  fontWeight: 400,
                  fontFamily: "Inter",
                  textTransform: "uppercase",
                  textAlign: "center",
                }}
              >
                {i18n.t("auth.recoverPassword.insertEmailCode")}
              </p>
            </Grid>
            <Grid item xs={12}>
              <ControlledFormInput
                control={control}
                name="token"
                inputPlaceholder={i18n.t("auth.recoverPassword.code", "Token").toUpperCase()}
              />
            </Grid>
          </Grid>
          {error && (
            <p
              style={{ textAlign: "center", marginTop: "20px", color: "red" }}
            >
              {error}
            </p>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: visitCard ? "20px" : "40px",
              rowGap: "20px",
              alignItems: "center",
              borderBottom: "1px solid #DDD8CF",
            }}
          >
            <ButtonBlue
              label={i18n.t("auth.recoverPassword.proceed")}
              textStyles={{
                textTransform: "uppercase",
                fontFamily: "Inter",
                fontSize: "13px",
                fontWeight: 500,
              }}
              styles={{
                backgroundColor: "rgba(255, 255, 255, 1)",
              }}
            />
          </div>
        </Box>
      </form>
    </>
  );
};

export default InputToken;
