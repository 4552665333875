import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Icons } from "../../../../../../../components/Icons";
import ControlledFormInput from "../../../../../../../components/Inputs/ControlledInputAdmin";
import { i18n } from "../../../../../../../translations/i18n";
import useChangePassword from "./useChangePassword";

import ButtonBlue from "../../../../../../../components/Ui/ButtonBlue";

const ChangePassword = ({ setMode, handleClose, visitCard, email, token }: any) => {
  const { handleSubmit, onSubmit, control, error } = useChangePassword(
    {
      handleClose,
    }
  );
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "10px" }}>
          <Box
            style={{
              padding: mobile
                ? "40px 40px 40px 40px"
                : visitCard
                  ? "70px 40px 40px 40px"
                  : "80px 65px 40px 65px",
              position: "relative",
            }}
          >
            <div
              onClick={() => setMode("login")}
              style={{
                position: "absolute",
                top: "30px",
                left: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                columnGap: "10px",
                cursor: "pointer",
              }}
            >
              <Icons.LeftArrow />
              <p
                style={{
                  textTransform: "uppercase",
                  textDecoration: "underline",
                  fontSize: "10px",
                  fontFamily: "Inter",
                  fontWeight: 400,
                  letterSpacing: "1px",
                }}
              >
                {i18n.t("auth.login.startSession")}
              </p>
            </div>
            <p
              style={{
                fontSize: visitCard ? "15px" : "20px",
                fontWeight: 600,
                textAlign: "center",
                letterSpacing: "1px",
                fontFamily: "Inter",
                textTransform: "capitalize",
              }}
            >
              {i18n.t("auth.login.passwordRecover").toUpperCase()}
            </p>
            <p
              style={{
                height: "28px",
                fontSize: "11px",
                fontWeight: 400,
                fontFamily: "Inter",
                textTransform: "uppercase",
                textAlign: "center",
                alignContent: "center"
              }}
            >
              {i18n.t("auth.recoverPassword.newPassword")}
            </p>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p
                  style={{
                    marginTop: "30px",
                    fontSize: "7px",
                    fontWeight: 400,
                    fontFamily: "Inter",
                    textTransform: "uppercase",
                    textAlign: "center",
                  }}
                >
                  {email}
                </p>
              </Grid>
              <Grid item xs={12}>
                <ControlledFormInput
                  control={control}
                  type="password"
                  name="password"
                  inputPlaceholder={i18n.t(
                    "pages.register.password",
                    "Password"
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledFormInput
                  control={control}
                  type="password"
                  name="confirmPassword"
                  inputPlaceholder={i18n.t(
                    "pages.register.confirmPassword",
                    "Confirm password"
                  )}
                />
              </Grid>
            </Grid>
            {error && (
              <p
                style={{ textAlign: "center", marginTop: "20px", color: "red" }}
              >
                {error}
              </p>
            )}
            <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: visitCard ? "20px" : "40px",
              rowGap: "20px",
              alignItems: "center",
              borderBottom: "1px solid #DDD8CF",
            }}
          >
            <ButtonBlue
              label={i18n.t("auth.recoverPassword.recover")}
              textStyles={{
                textTransform: "uppercase",
                fontFamily: "Inter",
                fontSize: "13px",
                fontWeight: 500,
              }}
              styles={{
                backgroundColor: "rgba(255, 255, 255, 1)",
              }}
            />
          </div>
          </Box>
        </form>
    </>
  );
};

export default ChangePassword;
